


import { Box, CssBaseline, Stack } from '@mui/material'



import Header from '../Components/Header' 
import MainPage from '../Pages/MainPage'
import Footer from '../Components/Footer'


const MintService = () => {


 
    return (
       
                <Box maxWidth="100%" sx={{ paddingBottom: "40px" }}>
                    
                    <Stack 
                        direction="column"
                        justifyContent="center"
                        alignItems="center"
                        maxWidth="100%">

                            <Stack
                                width={{xs: '100%', sm: '100%', md: '85%', lg: '85%',  xl: '80%'}}
                                direction="column"
                                display="flex"
                                justifyContent="start"
                                alignItems="start">
                                <Header/> 

                            </Stack>

                        <MainPage/>

                        <Stack
                                width={{xs: '100%', sm: '100%', md: '85%', lg: '85%',  xl: '80%'}}
                                direction="column"
                                display="flex"
                                justifyContent="center"
                                alignItems="center">

                                    
                                    <Footer/>


                                </Stack>

                    </Stack>
                   
                </Box>
        
    )

}


export default MintService 


