

import React, { useState, useEffect } from 'react' 

import Box from '@mui/material/Stack'
import Stack from '@mui/material/Stack'

import StartMint from '../Components/StartMint'
import PassView from '../Components/PassView' 

import { Web3ReactProvider } from '@web3-react/core'
import { Web3Provider } from  '@ethersproject/providers'
import { Typography } from '@mui/material'

function getLibrary(provider){
    return new Web3Provider(provider) 
}

const MainPage = () => {


    
    return (
        <Box mt={5}>
            <Stack 
                direction="column"
                spacing={0}
                maxWidth="100%"
                justifyContent="center"
                alignItems="center">

                <Stack 
                    maxWidth="100%"
                    justifyContent="center"
                    alignItems="center"
                    flexWrap="wrap"
                    direction={{xs: "column", sm: "column", md: "row", lg: "row"}}
                    spacing={{md: 8, lg: 10}}
                    width={{xs: '100%', sm: '100%', md: '85%', lg: '100%',  xl: '85%'}}
                    >

                    <Web3ReactProvider getLibrary={getLibrary}>
                        <StartMint/>
                        <PassView/>
                    </Web3ReactProvider>
                </Stack>
    


            </Stack>

           

        </Box>
    )
}

export default MainPage 