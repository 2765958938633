import Card from '@mui/material/Card'
import CardMedia from '@mui/material/CardMedia'


const WalletIcon = ({icon}) => {

    return (
                <CardMedia
                    sx={{height: '80px', width: {xs: '100px', sm: '100px', md: '100px'}, objectFit: 'contain'}}
                    component="img"
                    src={icon}
                    alt='Pass Frame'>

                </CardMedia>
                            
    )
}

export default WalletIcon 