

import React, {useState, useEffect} from 'react'

import CloseIcon from '@mui/icons-material/Close'
import Typography from '@mui/material/Typography' 
import Stack from '@mui/material/Stack'
import { IconButton, Box, Button, AppBar, Toolbar, Slide, Dialog, DialogContent, Grid, CardMedia, Card, CardContent, Alert, Link } from '@mui/material'
import ZoopTypography from './ZoopTypography'

import metamaskIcon from '../icons/metamask_3.png' 
import WalletIcon from './WalletIcon'
import coinbaseIcon from '../icons/coinbase.png'

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />
})


const InstallSupportedWallet  = ({status, handleDisplay}) => {



    const installMetaMask = () => {
        window.open('https://metamask.io/')
    }

    const installCoinBase = () => {
        window.open('https://chrome.google.com/webstore/detail/coinbase-wallet-extension/hnfanknocfeofbddgcijnmhnfnkdnaad')
    }
    return (
        <Box>

               {/* Wallet Nft Gallery */}
               <Dialog
                        maxWidth="100%"
                        sx={{ color: '#111321', borderRadius: '0'}}
                        open={status}
                        TransitionComponent={Transition}
                        keepMounted
                        aria-describedby="Display Message"
                    >

                        <AppBar sx={{ position: 'relative', backgroundColor: '#111321', boxShadow: 0, fontFamily: 'ClashDisplay-Regular'}}>
                            <Toolbar>
                                
                                <Stack 
                                    direction="column"
                                    spacing={1} 
                                    justifyContent="center"
                                    alignItems="center">

                              
                                </Stack>
                               
                              
                            </Toolbar>
                        </AppBar>

                        <DialogContent                       
                            sx={{ background: '#111321'}}>


                                <Stack 
                                    direction="column"
                                    spacing={1}
                                    justifyContent="center"
                                    alignItems="center">


                                   <Stack 
                                        direction="row" 
                                        spacing={5}>

                                            <Stack 
                                                direction="column" 
                                                spacing={3}>

                                                <IconButton
                                                    size="large"
                                                    aria-label="remove"
                                                    variant="outlined"
                                                    onClick={() => ( installMetaMask() )}
                            
                                                    sx={{ color: 'white'}}>
                                                            <WalletIcon icon={metamaskIcon}/>
                                                </IconButton>

                                              

                                            </Stack>


                                            <Stack 
                                                direction="column" 
                                                spacing={3}>

                                                <IconButton
                                                    size="large"
                                                    aria-label="remove"
                                                    variant="outlined"
                                                    onClick={() => ( installCoinBase() )}
                            
                                                    sx={{ color: 'white'}}>
                                                            <WalletIcon icon={coinbaseIcon}/>
                                                </IconButton>


                                            </Stack>
                                            
                                            
                                        </Stack>

                                    <Alert 
                                        variant="contained"
                                        severity="info">
                                            <ZoopTypography variant="h5" text="Install one of the supported wallet" font="ClashDisplay-Medium"/>

                                    </Alert>



                                   

                                </Stack>

                               
                            
                        
                        </DialogContent>
                        
                    </Dialog>
        </Box>
    )

}

export default InstallSupportedWallet