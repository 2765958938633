

import React, {useState} from 'react' 

import Typography from '@mui/material/Typography'


const ZoopTypography = ({variant, text, font}) => {
    
    return (
        <Typography 
            variant={variant}
            component="div"
            fontFamily={font}
            wrap="wrap"
            color="white">
                {text} 
        </Typography>
    )
}

export default ZoopTypography 