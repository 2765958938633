
import React, { useState, useEffect } from 'react' 

import Box from '@mui/material/Stack'
import Stack from '@mui/material/Stack'
import ZoopTypography from '../Components/ZoopTypography'
import Typography from '@mui/material/Typography'
import CircularProgress from '@mui/material/CircularProgress'
import CloseIcon from '@mui/icons-material/Close'
import Dialog from '@mui/material/Dialog'
import DialogContent from '@mui/material/DialogContent'
import Slide from '@mui/material/Slide'
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline'
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline'
import { AppBar, Button, Chip,  Divider, IconButton, Link, Toolbar, CardMedia } from '@mui/material'
import {makeStyles} from '@mui/styles'  

import Minted from '../Components/Minted' 
import PassCollection  from './PassGallery'  
import WalletIcon from './WalletIcon'
import DialogMessage from './DialogMessage'

import { ethers }  from 'ethers'
import { useWeb3React } from '@web3-react/core'
import { WalletLinkConnector } from '@web3-react/walletlink-connector'


import CoinbaseWalletSDK from '@coinbase/wallet-sdk'
import Web3Modal from 'web3modal'

// wallet connect 
import WalletConnect from '@walletconnect/client'
import QRCodeModal from '@walletconnect/qrcode-modal'



import metamaskIcon from '../icons/metamask_3.png' 
import coinbaseIcon from '../icons/coinbase.png'
import coinbaseIcon2 from '../icons/coinbase2.png'
import walletconnectIcon from '../icons/walletconnect.png' 
import Mint from './MobileMint'

import contractProd from './prod/ZoopProductionABI.json'
import contractTest from './staging/ZoopTest.json'
import InstallSupportedWallet from './InstallSupportedWallet'
import IErrorDisplay from './IErrorDisplay'

let contract = contractTest
if (process.env.REACT_APP_ENV === 'production') {
    console.log('using production contract');
    contract = contractProd
} else {
    console.log('using test contract');
}

const defaultChainID = process.env.REACT_APP_CHAINID 
const defaultContractAddress = process.env.REACT_APP_CONTRACT_ADDRESS 
const defaultIpfs = process.env.REACT_APP_IPFS_URL
const defaultProvider = process.env.REACT_APP_URL
const PUBLIC_KEY = process.env.REACT_APP_PUBLIC_KEY 
const SignMessage = process.env.REACT_APP_SIGN_MESSAGE
const logged = localStorage.getItem('currentwallet')
const Web3 = require('web3')

const defaultMintQuantity = 1
const defaultMintPrice = parseFloat(process.env.REACT_APP_SALE_PRICE_ETH) || 0.074;



/* UTILS STARTS HERE */

// detect mobile browser
const isMobile = () => {
    const toMatch = [
        /Android/i,
        /webOS/i,
        /iPhone/i,
        /iPad/i,
        /iPod/i,
        /BlackBerry/i,
        /Windows Phone/i
    ]

    return toMatch.some((toMatchItem) => {
        return navigator.userAgent.match(toMatchItem)
    })
}

const useStyles = makeStyles({
    button:{
        backgroundColor: '#ffffff',
        color: '#000000', 
        '&:hover': {
            backgroundColor: '#eb4034',
            color: '#ffffff',
        },
    },
})




const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />
})

/* UTILS ENDS HERE */



const StartMint = () => {


    // blockchain core 
    const {ethereum} = window 
    const abi = contract.abi 
    const connector = new WalletConnect({
        bridge: "https://bridge.walletconnect.org",
        qrcodeModal: QRCodeModal,
        
    })
    const coreProvider = new Web3(defaultProvider)
    
        

    const coinbaseConnector = new WalletLinkConnector({
        url: defaultProvider, 
        appName: 'Zoop Mint',
        supportedChainIds: defaultChainID
    })

   



    const {activate, deactivte} = useWeb3React() 
 


    /* Basic Hooks */
    

    // Wallet, Chain Id, Contract Address 
    const [currentWallet, setCurrentWallet] = useState(null) 
    const [storageWallet, setStorageWallet] = useState(logged)
    const [chainID, setChainID] = useState(defaultChainID) 
    const [contractAddress, setContractAddress] = useState(defaultContractAddress)

    const [totalSupply, updateTotalSupply] = useState(null) 
    const [currentMinted, updateCurrentMinted] = useState(null)
    const [saleActive, setSaleActive] = useState(false) 

    const [mintQuantity, setMintQuantity] = useState(defaultMintQuantity) 
    const [mintPrice, setMintPrice] = useState(defaultMintPrice)


    const [isMetaMask, setIsMetaMask] = useState(false)
    const [isCoinBase, setIsCoinBase] = useState(false)

    const [isMinning, setIsMinning] = useState(false) 
    const [isMinned, setIsMinned] = useState(false) //changed   
    const [isSigned, setIsSigned] = useState(false)
    const [signedDialog, setSignedDialog] = useState(false)
    const [signedMobileDialog, setSignedMobileDialog] = useState(false) 
    const [supportWalletInstalled, setsupportWalletInstalled] = useState(false)
    const [isValidMobileChain, setIsValidMobileChain] = useState(false) 

    const [messageInfo, setMessageInfo] = useState(null)
    const [fullMessage, setFullMessage] = useState('no Message')
    const [messageIndicator, setMessageIndicator] = useState(null)
    const [messageStatus, setMessageStatus] = useState(false) 


    const [dialogStatus, setDialogStatus] = useState(false) 
    const [dialogMessage, setDialogMessage] = useState() 
    const [dialogIndicator, setDialogIndicator] = useState()


    const [openNft, setOpenNft] = useState(false) 
    const [openWallet, setOpenWallet] = useState(false) 
    const  classes = useStyles() 

    /* Dialog Handlers */

    const handleDisplay = () => {
        setDialogStatus(false) 
    }

    const handleSupportWalletInstalled = () => {
        setsupportWalletInstalled(false) 
    }

    const messageClose = () => {
        setMessageStatus(false) 
    }

    const handleWalletDisplay = () => {
        setOpenWallet(true)
    }

    const handleWalletClosed = () => {
        setOpenWallet(false) 
    }

    const handleSignedDialog = () => {
        setSignedDialog(false)
    }


    const handleSignedMobileDialog = () => {
        setSignedMobileDialog(false)
    }
    const handleNftDialog = () => {
        setOpenNft(!openNft) 
    }

    const connectCoinBase =  () => {

        activate(coinbaseConnector)
        handleWalletClosed()

        let address = localStorage.getItem('-walletlink:https://www.walletlink.org:Addresses')
        setCurrentWallet(address)
        setMessageInfo(`Connected: ${address}`)
        setMessageIndicator('#18CDBC')

        localStorage.setItem('currentwallet', address) 
        setIsCoinBase(true)
       
    }

    /* WalletConnect Mobile Connection */
    const connectMobileWallet = async () => {

          
        if (!connector.connected) {
            await connector.createSession()
        }
          
        connector.on("connect", (error, payload) => {
            if (error) {
            console.log(error) 
            }
          
            const { accounts, chainId } = payload.params[0]

            if(chainId == chainID){
                setCurrentWallet(accounts[0])
                localStorage.setItem('currentwallet', accounts[0])
                setMessageInfo(`Connected: ${accounts[0]}`)
                setMessageIndicator('#18CDBC')
                setIsValidMobileChain(true)
                handleWalletClosed() 
                return 
            }

            setDialogMessage('Make sure you are connected to Ethereum Mainnet before minting!')
            setDialogIndicator('info')
            setDialogStatus(true)
            setIsValidMobileChain(false) 
            handleWalletClosed()
            return 
        })
          
        connector.on("session_update", (error, payload) => {
            if (error) {
              throw error
            }
          
            const { accounts, chainId } = payload.params[0]

            if(chainId == chainID){
                setCurrentWallet(accounts[0])
                setMessageInfo(`Connected: ${accounts[0]}`)
                setMessageIndicator('#18CDBC')
                handleWalletClosed() 
                setIsValidMobileChain(true) 
                return 
            }

            setDialogMessage('Make sure you are connected to Ethereum Mainnet before minting!')
            setDialogIndicator('info')
            setDialogStatus(true)
            setIsValidMobileChain(false) 
            handleWalletClosed()
            return 



        })
          
        connector.on("disconnect", (error, payload) => {
            if (error) {
                console.error(error)
              throw error
            }
            localStorage.removeItem('walletconnect')
          
        })

    }



    const disconnectWalletMobile = () => {
        if(connector.connected){
            localStorage.removeItem('currentwallet')
            localStorage.removeItem('walletconnect')
            localStorage.removeItem('WALLETCONNECT_DEEPLINK_CHOICE')
            window.location.reload()
        }
    }

   

    /* Check if Metamask | Coinbase is installed on desktop */
    const issupportWalletInstalled = () => {
        if(!ethereum){
            return false
        }
        return true 
    }

    /* Extract Accounts from Metamask */
    const extractAccounts = async () => {
        if(ethereum.providers){
            let metaMaskProvider = ethereum.providers.find((provider) => provider.isMetaMask)  
            const accounts = await metaMaskProvider.request({method: 'eth_requestAccounts'})
            return accounts 
        }

        const accounts = await ethereum.request({method: 'eth_requestAccounts'})
        return accounts 
        
    }


    /*  Validate if Metamask has an Account */
    const hasAccounts = () => {
       if(extractAccounts.length == 0){
           return false
       }
       return true 
    }

    /* Check if Metamask has an account connect to webApp */
    const isAccountConnected = () => {
        if(connector.connected){
            return true 
        }
        else if (currentWallet == null || currentWallet == undefined){
            return false
        }
        return true 
    }

    /* Metamask Wallet Connection Desktop Only */


     // detect if user is connected to accepted chain id 
    const isValidChainForMinting = () => {

        if(ethereum.providers){
            let metaMaskProvider = ethereum.providers.find((provider) => provider.isMetaMask)
            let coinbaseProvider = ethereum.providers.find((provider) => provider.isCoinBase)

            if(isCoinBase){
                if(!isMobile() && ethereum && ethereum.networkVersion == chainID){
                    return true 
                }
                return false 
            }


            if(!isMobile() && metaMaskProvider && metaMaskProvider.networkVersion == chainID){
            return true
            }
            return false  
        }

        if(!isMobile() && ethereum && ethereum.networkVersion == chainID){
            return true
        }
        return false 
      
    }



    const connectWalletHandler = async () => {


        if(!ethereum.isMetaMask){
            console.log('metamask not installed')
            return 
        }

        if(!hasAccounts()){
           displayMessage('Metamask is not connected', 'error')
        }

        if(!isValidChainForMinting()){
            setDialogMessage('Make sure you are connected to Ethereum Mainnet before minting!')
            setDialogIndicator('info')
            setDialogStatus(true)
        }

         

        let accounts = await extractAccounts() 
        setCurrentWallet(accounts[0])
        localStorage.setItem('currentwallet', accounts[0])
        setMessageInfo(`Connected: ${accounts[0]}`)
        setMessageIndicator('#18CDBC')
        handleWalletClosed() 
        setIsMetaMask(true)

    }

    /* End Metamask wallet connection 8? 



    /* Display Message (Currently not in used) */

    const displayMessage = (message, indicator) => {
        setMessageInfo(message)
        setMessageIndicator(indicator)
        setMessageStatus(true)
    }


    /* Need some attention 
      Current Issue: opens Metamask on mobile and close without
                     enabling a transaction 
    ------------------------------------------------------------
                NEEDS IMMEDIATE ATTENTION 
    ------------------------------------------------------------
    */



    const mobileMint = async () => {
 
        const contract = new coreProvider.eth.Contract(abi, contractAddress)
        if(!connector.connected){
            console.log('Disconnect')
        }

            
        /*
        const messagex = SignMessage 
        let hashMessagex = coreProvider.utils.sha3(messagex) 
        const msgParams =  [hashMessagex,  currentWallet] 


        const result = await connector.signPersonalMessage(msgParams)

        let rx =  result.slice(0, 66)
        let sx = "0x" + result.slice(66, 130)
        let vx = parseInt(result.slice(130, 132), 16)    

        */


        
        let mint_value = coreProvider.utils.toWei(mintPrice.toString(), 'ether')
       // console.log('Mint Value', mint_value)
        const tx = {
            from: currentWallet, 
            to: contractAddress, 
            value: mint_value,
            data: await contract.methods.mintMobile(currentWallet,  mintQuantity).encodeABI()
        }

        await connector.sendTransaction(tx)
                    .then((result) => {
                        setIsMinned(true)
                    })
                    .catch((error) => {
                        setMessageIndicator('#ff9494')
                        setMessageInfo(error.message) 
                        setIsMinning(false)
                    })
                
    }


    

    const [hashMessage, setHashMessage] = useState()
    const [r, setR] = useState() 
    const [v, setV] = useState() 
    const [s, setS] = useState()

    const signAgreementDesktop = async () => {
        // Sign Agreement First before mint 
        if(ethereum.providers){
            let metaMaskProvider = ethereum.providers.find((provider) => provider.isMetaMask)
    
            const message = SignMessage 
            const hashMessage = coreProvider.utils.sha3(message) 
                const signature = await  metaMaskProvider.request({
                    method: 'personal_sign', 
                    params: [hashMessage, currentWallet], 
                })
                    .then((res) => {
                        const rx =  res.slice(0, 66)
                        const sx = "0x" + res.slice(66, 130)
                        const vx = parseInt(res.slice(130, 132), 16)
                        setR(rx)
                        setS(sx)
                        setV(vx) 
                        setIsSigned(true)
                        setSignedDialog(false)
                    })
                    .catch((error) => {
                        setIsMinning(false)
                        setSignedDialog(false)
                        console.error(error)
                    })

                
                    setHashMessage(hashMessage)  
                    return      
        }

        const message = SignMessage 
            const hashMessage = coreProvider.utils.sha3(message) 
                const signature = await ethereum.request({
                    method: 'personal_sign', 
                    params: [hashMessage, currentWallet], 
                })
                    .then((res) => {
                        const rx =  res.slice(0, 66)
                        const sx = "0x" + res.slice(66, 130)
                        const vx = parseInt(res.slice(130, 132), 16)
                        setR(rx)
                        setS(sx)
                        setV(vx) 
                        setIsSigned(true)
                        setSignedDialog(false)
                    })
                    .catch((error) => {
                        setIsMinning(false)
                        setSignedDialog(false)
                        console.error(error)
                    })

                
                    setHashMessage(hashMessage)     



    }

    // Sign for Coinbase to avoid conflicts with metamask.

    const signAgreementDesktopCB = async () => {

        const message = SignMessage 
        const hashMessage = coreProvider.utils.sha3(message) 
        const signature = await ethereum.request({ 
                method: 'personal_sign', 
                params: [hashMessage, currentWallet], 
            })
            .then((res) => {
                    const rx =  res.slice(0, 66)
                    const sx = "0x" + res.slice(66, 130)
                    const vx = parseInt(res.slice(130, 132), 16)
                    setR(rx)
                    setS(sx)
                    setV(vx) 
                    setIsSigned(true)
                    setSignedDialog(false)
            })
            .catch((error) => {
                    setIsMinning(false)
                    setSignedDialog(false)
                    console.error(error)
            })            
        setHashMessage(hashMessage)       
    }




    /*
        Desktop Minting(Works Perfectly, Bit messy)
        Mint: 
            get currentWallet signature(if approved)
            connect to contract and call the mint function with currentWallet,
            quantity to mint and value that's convert to ethereum parser
    */

    
    const desktopMint = async () => {
        if(ethereum.providers){
            let metaMaskProvider = ethereum.providers.find((provider) => provider.isMetaMask)
    
        
            if(isMetaMask){

                const provider = new ethers.providers.Web3Provider(metaMaskProvider)
                const signer = provider.getSigner() 
                const nftContract = new ethers.Contract(contractAddress, abi, signer)

                let nft_receipt = await nftContract.mint(currentWallet,  mintQuantity, hashMessage, v, r, s, {value: ethers.utils.parseEther(parseMintPrice())} )
                                .then((tx) => {
                                    provider.waitForTransaction(tx.hash)
                                // console.log(tx.hash) 
                                }).then((res) => { 
                                    setIsMinned(true) 
                                    
                                }).catch((error) => {
                                    console.error(error)
                                    setMessageIndicator('#ff9494')
                                    setMessageInfo(error.reason) 
                                    setFullMessage(error)
                                    setIsMinning(false) 
                                })

            
            }else if(isCoinBase){

                const provider = new ethers.providers.Web3Provider(ethereum)
                const signer = provider.getSigner() 
                const nftContract = new ethers.Contract(contractAddress, abi, signer)

                let nft_receipt = await nftContract.mint(currentWallet,  mintQuantity, hashMessage, v, r, s, {value: ethers.utils.parseEther(parseMintPrice())} )
                                .then((tx) => {
                                    provider.waitForTransaction(tx.hash)
                                // console.log(tx.hash) 
                                }).then((res) => { 
                                    setIsMinned(true) 
                                    
                                }).catch((error) => {
                                    console.error(error)
                                    setMessageIndicator('#ff9494')
                                    setMessageInfo(error.message) 
                                    setIsMinning(false) 
                                })

            }
            return 
        }

        const provider = new ethers.providers.Web3Provider(ethereum)
        const signer = provider.getSigner() 
        const nftContract = new ethers.Contract(contractAddress, abi, signer)

        let nft_receipt = await nftContract.mint(currentWallet,  mintQuantity, hashMessage, v, r, s, {value: ethers.utils.parseEther(parseMintPrice())} )
                        .then((tx) => {
                            provider.waitForTransaction(tx.hash)
                        // console.log(tx.hash) 
                        }).then((res) => { 
                            setIsMinned(true) 
                            
                        }).catch((error) => {
                            console.error(error)
                            setMessageIndicator('#ff9494')
                            setMessageInfo(error.message) 
                            setIsMinning(false) 
                        })


    }

    /* 
        If Mobile User, call mobileMint(), 
        If Desktop User, call desktopMint()
        (NOT IN USED)
    */

    const initializeMint = async () => {
        setIsMinning(true)
        if(isMobile()){
            await mobileMint()
        }
        await desktopMint() 
    }

    /* If User Chain is changed, set it back to Default ChainID */

    const handleChainChanged = (chain_id) => {
        if(chain_id !== chainID){
            setDialogMessage('Make sure you are connected to Ethereum Mainnet before minting!')
            setDialogIndicator('info')
            setDialogStatus(true)
        }

        setChainID(defaultChainID)
    }

    /* If currentWallet is Changed, set currentWallet to Metamask accounts[0] */

    const handleAccountChanged = (new_account) => {
        if(new_account.length == 0){
            displayMessage('Metamask is not Connected', 'error') 
            return 
        }else if(new_account !== currentWallet){
            setCurrentWallet(new_account[0])
            localStorage.setItem('currentwallet', new_account[0])
            setMessageInfo(`Connected: ${new_account}`)
            setMessageIndicator('#18CDBC')        }
    }

    /*  if Metamask is disconnected, 
        set currentWallet to '0x0',
        setIsMinning -> False 
    */

    const handleMetamaskDisconnect = () => {
        setIsMinning(false) 
        setCurrentWallet('0x0') 
    }



    /* Handle Mint Quantity Increased */
    const increaseQuantity = () => {
      
            setMintQuantity( (quantity) => quantity += 1)
            setMintPrice( (price) => price += defaultMintPrice) 
    }

    
    /* Handle Mint Quantity Decreased */
    const decreaseQuantity = () => {

        setMintQuantity((amount) => amount -= 1)
        setMintPrice( (price) => price -= defaultMintPrice) 
 
    }

    
    /* Handle Parsing the Mint Price */
    const parseMintPrice = () => {
        return parseFloat(mintPrice).toFixed(3)
    }

    /* handle error message popup */
    const [errordisplay, setErrorDisplay] = useState(false)

    const detectError = () => {
        if(messageIndicator == '#ff9494'){
            return true
        }
        return false 
    }

    const handleErrorDisplay = () => {
        setErrorDisplay(false) 
    }


    /* Loading User Pass */



    /* Alert User when token/transaction is minning */
 
    const AlertMinning = () => {
        return (
            <Box>
            {!isMobile() ? 
            (
            <Stack 
                direction="column"
                spacing={1}
                >

                    <ZoopTypography variant="p" text="Your mint transaction is in progress!" font="ClashDisplay-Light"/>
                    <ZoopTypography variant="p" text="Once your transaction is completed, click on view minted pass " font="ClashDisplay-Light"/>
                   
            </Stack>
            ):(
                <Stack 
                    direction="column"
                    spacing={1}
                    justifyContent="center"
                    alignItems="center">

                        <ZoopTypography variant="p" text="Your mint transaction is in progress!" font="ClashDisplay-Light"/>
                        <ZoopTypography variant="p" text="Once your transaction is completed" font="ClashDisplay-Light"/>
                        <ZoopTypography variant="p" text="click on view minted pass " font="ClashDisplay-Light"/>

                    
                </Stack>
            )}
            </Box>
        )
    }

    /* Display Certain UI when token/transaction is not minning */

    const notMinning = () => {
        return (
           
                <Box>
                    {!isMobile() ? (
                        <Stack 
                            direction="column"
                            spacing={1}
                            >
                            <Stack 
                                direction="column"
                                spacing={1}
                                justifyContent="start"
                                alignItems="start">
                                <Typography
                                    component="h1"
                                    fontFamily="ClashDisplay-Regular"
                                    wrap="wrap"
                                    sx={{ fontSize: "64px", lineHeight: "78px", fontWeight: 600, marginBottom: "16px", color: "#FFFFFF", width: "490px" }}
                                >
                                    Mint your Zoop Priority Pass
                                </Typography> 

                                <Stack 
                                    direction="column"
                                    spacing={3}>
                                    <Typography font="ClashGrotesk-Regular" sx={{ color: "#FFFFFF", fontWeight: 300, fontSize: 16, lineHeight: 1.3, width: 384 }}>
                                        The exclusive Zoop Priority Pass rewards its holders with special benefits and experiences both in-app and in real life that won't be available anywhere else!
                                    </Typography>
                                    <Typography font="ClashGrotesk-Regular" sx={{ color: "#FFFFFF", fontWeight: 300, fontSize: 16, lineHeight: 1.3, width: 384 }}>
                                        Passes are limited in number though and will only be available to mint for a short time!
                                    </Typography>
                                </Stack>
                                {isAccountConnected() && 

                                <Button    
                                    sx={{ 
                                        width: {xs: "10rem", sm: "10rem", md: "12rem", lg: "12rem"},
                                        height: '2.5rem', backgroundColor: 'transparent',  color: 'white', fontSize: '.7rem', 
                                        fontStyle: 'bold',  fontFamily: "ClashDisplay-Regular",border: '2px solid black',   borderColor: 'white', borderRadius: '10px',  opacity: '.8',
                                        ":hover": { backgroundColor: '#18CDBC', color: '#ffffff', opacity: '1', fontStyle: 'bold'}
                                    }}
                                    variant="outlined"
                                    onClick={() => ( handleNftDialog())}>
                                        View Minted Pass 
                                </Button>
                                 }
                            </Stack>
                            {/*
                            <Stack 
                                direction="row"
                                spacing={1}
                                justifyContent="center"
                                alignItems="Center">
        
                            
                                <ZoopTypography variant="h6" text={currentMinted} />
                                <ZoopTypography variant="h6" text={`out of ${totalSupply}`}/>
        
                            </Stack>
                            */}
                        </Stack>
                    ): (
                        <Stack 
                            direction="column"
                            spacing={1}
                            justifyContent="center"
                            alignItems="center">
        
                        <Stack 
                            direction="column"
                            spacing={1}
                            >
    
                            <Typography 
                                    variant="h4"
                                    component="div"
                                    fontFamily="ClashDisplay-Semibold"
                                    wrap="wrap"
                                    color="white">
                                        MINT YOUR ZOOP 
                            </Typography> 


                            <Typography 
                                    variant="h4"
                                    component="div"
                                    fontFamily="ClashDisplay-Semibold"
                                    wrap="wrap"
                                    color="white">
                                        PRIORITY PASS  
                            </Typography> 

                    
                            <Stack 
                                direction="column"
                                spacing={3}>
                                    <Stack 
                                        direction="column"
                                        spacing={0}>
                                        <ZoopTypography variant="p" text="The exclusive Zoop Priority Pass rewards its " font="ClashDisplay-Light"/>
                                        <ZoopTypography variant="p" text="holders with special benefits and experiences " font="ClashDisplay-Light"/>
                                        <ZoopTypography variant="p" text="both in-app and in real life that won't be available" font="ClashDisplay-Light"/>
                                        <ZoopTypography variant="p" text="anywhere else!" font="ClashDisplay-Light"/>
                                    </Stack>

                                    <Stack 
                                        direction="column"
                                        spacing={0}>
                                                <ZoopTypography variant="p" text="Passes are limited in number though and will only" font="ClashDisplay-Light"/>
                                                <ZoopTypography variant="p" text="be available to mint for a short time!" font="ClashDisplay-Light"/>
                                    </Stack>
                            </Stack>
                            {isAccountConnected() && 

                                <Stack 
                                    direction="column"
                                    spacing={3}
                                    justifyContent="center"
                                    alignItems="center">

                                    <Button    
                                        sx={{ 
                                            width: {xs: "10rem", sm: "10rem", md: "12rem", lg: "12rem"},
                                            height: '2.5rem', width: '100%', backgroundColor: 'transparent',  color: 'white', fontSize: '.7rem', 
                                            fontStyle: 'bold',  fontFamily: "ClashDisplay-Regular",border: '2px solid black',   borderColor: 'white', borderRadius: '10px',  opacity: '.8',
                                            ":hover": { backgroundColor: '#18CDBC', color: '#ffffff', opacity: '1', fontStyle: 'bold'}
                                        }}
                                        variant="outlined"
                                        onClick={() => ( handleNftDialog())}>
                                        View Minted Pass 
                                    </Button>
                                </Stack>
                            }

                        </Stack>
    
                         {/*
                            <Stack 
                                direction="row"
                                spacing={1}
                                justifyContent="center"
                                alignItems="Center">
        
                                
                                <ZoopTypography variant="h6" text={currentMinted} />
                                <ZoopTypography variant="h6" text={`out of ${totalSupply}`}/>
        
                            </Stack>
                        */}
                        
                    </Stack>
                    )}
                </Box>
        )
    }


    /* Display Certain UI when totalSupply reached */

    const SaleOver = () => {
        return (
            <>
                {!isMobile() ? (
                    <Stack
                        maxWidth="100%"
                        direction="column"
                        alignItems="flex-start"
                        width={{ xs: "90%", sm: 470, md: 470, lg: 470, xl: 470 }}
                    >
                        <ZoopTypography variant="h2" text="Priority Pass Sale is Over" font="ClashDisplay-Semibold"/>

                        <Box sx={{ marginTop: "40px", fontFamily: "ClashGrotesk-Regular", fontSize: "16px", lineHeight: "22px" }}>
                            <Box sx={{ marginBottom: "32px", bgcolor: "#21264E", borderRadius: "8px", padding: "12px" }}>
                                <Typography variant="p" sx={{ color: "white" }}>
                                    Please be advised that the sale of the Zoop Priority Pass NFT was officially closed on 16th October 2022 at 12pm Eastern Time. 
                                </Typography>
                            </Box>
                            <Typography variant="p" sx={{ color: "white", marginBottom: "20px" }}>
                                All purchasers of the Zoop Priority Pass NFT understand and acknowledge that any passes minted after the closure of the sale on 16th October 2022 will not be valid.
                            </Typography>
                            <Typography variant="p" sx={{ color: "white" }}>
                                Zoop reserves the right to disable access to users that are in contravention of this provision, and/or deny you access to any further benefits, services or goods associated with the Zoop Priority Pass NFT, including the NFT Access Privileges.
                            </Typography>
                        </Box>
                    </Stack>
                ) : (
                    <Stack
                        maxWidth="100%"
                        direction="column"
                        alignItems="flex-start"
                        width={{ xs: "90%", sm: 470, md: 470, lg: 470, xl: 470 }}
                    >
                        <ZoopTypography variant="h4" text="Priority Pass Sale is Over" font="ClashDisplay-Semibold"/>

                        <Box sx={{ marginTop: "32px", fontFamily: "ClashGrotesk-Regular", fontSize: "16px", lineHeight: "22px" }}>
                            <Box sx={{ marginBottom: "32px", bgcolor: "#21264E", borderRadius: "8px", padding: "12px" }}>
                                <Typography variant="p" sx={{ color: "white" }}>
                                    Please be advised that the sale of the Zoop Priority Pass NFT was officially closed on 16th October 2022 at 12pm Eastern Time. 
                                </Typography>
                            </Box>
                            <Typography variant="p" sx={{ color: "white", marginBottom: "20px" }}>
                                All purchasers of the Zoop Priority Pass NFT understand and acknowledge that any passes minted after the closure of the sale on 16th October 2022 will not be valid.
                            </Typography>
                            <Typography variant="p" sx={{ color: "white" }}>
                                Zoop reserves the right to disable access to users that are in contravention of this provision, and/or deny you access to any further benefits, services or goods associated with the Zoop Priority Pass NFT, including the NFT Access Privileges.
                            </Typography>
                        </Box>
                    </Stack>
                )}
            </>
        )
    }

    /* StartMint.js Loads perform certain tasks */
    useEffect(() => {

        const checkMobileConnector = () => {
            if(connector.connected){
                setCurrentWallet(connector.accounts[0])
                setMessageInfo(`Connected: ${connector.accounts[0]}`)
                setMessageIndicator('#18CDBC')          
            }
        }

        const checkForMetaMask = () => {
            if(!isMobile() && !ethereum){
                setsupportWalletInstalled(true)
            }
        }

        const contract = new coreProvider.eth.Contract(abi, contractAddress)
        
        // get sale status 
        const getSaleStatus = async () => {
            // const status = await contract.methods.saleStatus().call((error, result)=> {
            //     //console.log(result)
            // });
            const status = false;
            setSaleActive(status) 
        }

        // get Total Supply from contract 
        const getTotalSupply = async () => {
           
            const supply = await contract.methods.totalSupply().call((error, result) => {
              //  console.log(result)
            })
            updateTotalSupply(supply)
        }

        // extract currently minted tokens amount 
        const getCurrentMinted = async () => {
            const current_mint = await contract.methods.getTokenId().call((error, result) => {
               // console.log(result)
            })

            updateCurrentMinted(current_mint)
        }

      


        // when user is the owner of the contract, ask to withdraw
        const withdrawFunds = async () => {
            const status = await contract.methods.saleStatus().call()
            if(currentWallet == PUBLIC_KEY){
                
                console.log('Withdrawing...........')
                console.log('OWNER OF CONTRACT', PUBLIC_KEY)
                const provider = new ethers.providers.Web3Provider(ethereum)
                const signer = provider.getSigner()
                const contract = new ethers.Contract(contractAddress, abi, signer)
                let withdrawal = await contract.withdraw({from: currentWallet})
                                .then((tx) => {
                                    provider.waitForTransaction(tx.hash)
                                    console.log(tx.hash)
                                })
                                .then(res => {
                                    console.log('Withdrawing to Owner')
                                }).catch((error) => {
                                    console.error(error) 
                                })
           }
        }
        

        

        // metamask provider events
        const metaMaskListener = async () => {
            if(!isMobile() && ethereum){
                await ethereum.on('accountsChanged', handleAccountChanged)
                await ethereum.on('chainChanged', handleChainChanged)
                await ethereum.on('disconnect', handleMetamaskDisconnect)
            }
        }

     


        checkForMetaMask()
        checkMobileConnector()
        withdrawFunds()
        getSaleStatus()
        getTotalSupply()
        getCurrentMinted()
        metaMaskListener() 
    


    }, [currentWallet])

   console.log('CURRENT WALLET', currentWallet)
   console.log('PUBLICKEY', PUBLIC_KEY)

   console.log('Is Coin Base', isCoinBase)
   console.log('IS Metamask ', isMetaMask)
   console.log('Is Valid Mobile Chain', isValidMobileChain)
   console.log('Message Indicator: ', messageIndicator) 

  
   if(!isMobile() && ethereum){
        console.log('Current Chain', ethereum.networkVersion)
   }
    return (

        <Box 
        justifyContent="center"
        alignItems="center"            
            maxWidth="100%">

        {saleActive ? (

        <Box            
            maxWidth="100%"
        >
        {!isMinned? (
        <Stack 
            maxWidth="100%"
            sx={{ width: '400px'}}
            direction="column"
            spacing={1}
            alignItems="left"           
            justifyContent="left"
        >

           
            {isMinning ? (
                AlertMinning()
            ) : (
                 notMinning() 
            )}
           
            
                <Stack
                    maxWidth="100%"
                    direction="column"
                    justifyContent="center"
                    alignItems="center"
                    spacing={2}
                    mt={50}>

                <Typography 
                    mt={5}
                    variant="p"
                    component="div"
                    wrap="wrap"
                    color="white">
                        Enter the amount  
                </Typography>
                <Box mt={10}></Box>


                <Stack 
                    maxWidth="100%"
                    direction="row"
                    spacing={5}>

                        {mintQuantity > 1 ? (
                            <IconButton
                                size="large"
                                aria-label="remove"
                                variant="outlined"
                                onClick={decreaseQuantity} 
                                sx={{ color: 'white'}}>
                                    <RemoveCircleOutlineIcon/>
                            </IconButton>
                        ): (

                            <IconButton
                            size="large"
                            aria-label="remove"
                            variant="outlined"
                            disabled
                            sx={{ color: 'white'}}>
                                <RemoveCircleOutlineIcon/>
                        </IconButton>
                        )}
                       


                        <Typography 
                            mt={10}
                            variant="h3"
                            component="div"
                            wrap="wrap"
                            color="white">
                                {mintQuantity} 
                        </Typography>



                        {mintQuantity > 4 ? (
                            <IconButton
                                size="large"
                                aria-label="remove"
                                variant="outlined"
                                disabled 
                                sx={{ color: 'white'}}>
                                    <AddCircleOutlineIcon/>
                            </IconButton>
    
                        ) : (
                        <IconButton
                            size="large"
                            aria-label="remove"
                            variant="outlined"
                            onClick={increaseQuantity}
                            sx={{ color: 'white'}}>
                                <AddCircleOutlineIcon/>
                        </IconButton>
                        )}


                </Stack>

                <Stack 
                    maxWidth="100%"
                    direction="row"
                    justifyContent="center"
                    alignItems="center" 
                    spacing={20}
                    >

                    
                    <Typography 
                        sx={{ left: 0}}
                        fontSize="16px"
                        lineHeight="20px"
                        component="div"
                        wrap="wrap"
                        fontFamily="ClashDisplay-Regular"
                        color="white">
                            Price 
                    </Typography>

                    <Typography 
                        sx={{ right: 0}}
                        fontSize="16px"
                        lineHeight="20px"
                        textTransform="uppercase"
                        component="div"
                        wrap="wrap"
                        fontFamily="ClashDisplay-Regular"
                        color="#18CDBC">
                            {defaultMintPrice} &nbsp; &nbsp; ETH
                    </Typography>

                </Stack>

                <Stack 
                    maxWidth="100%"
                    direction="column"
                    spacing={3}
                    width="85%">
                <Divider sx={{ backgroundColor: "#21264E", width: "100%" }}/>
                </Stack>

                <Stack 
                    direction="row"
                    spacing={20}
                    >

                    
                    <Typography 
                        sx={{ left: 0}}
                        fontSize="16px"
                        lineHeight="20px"
                        component="div"
                        wrap="wrap"
                        fontFamily="ClashDisplay-Regular"
                        color="white">
                            Total
                    </Typography>

                    <Typography 
                        sx={{ right: 0, }}
                        fontSize="16px"
                        lineHeight="20px"
                        textTransform="uppercase"
                        component="div"
                        wrap="wrap"
                        fontFamily="ClashDisplay-Regular"
                        color="#18CDBC">
                            {parseMintPrice()} &nbsp; &nbsp; ETH
                    </Typography>


                </Stack>


                <Box mt={10}></Box>


                    <Stack 

                        direction="row"
                        spacing={2}
                        >

                            

                            { isAccountConnected() ? (
                            <Box maxWidth="100%">
                                {!isMobile() ? (
                                        <Chip
                                            variant="outlined"
                                            
                                            sx={{
                                                width: "11.5rem", height: '3rem', backgroundColor: 'transparent', color: 'white', fontSize: '1rem', 
                                                fontFamily: "ClashDisplay-Regular", fontWeight: 500, border: '1px solid white', borderRadius: '10px', textTransform: "none",
                                                ":hover": { backgroundColor: "transparent", borderColor: '#18CDBC', color: '#18CDBC', }
                                            }}
                                            label="Connected"
                                            >
                                        </Chip>
                                ): (
                                        <Button 
                                            variant="outlined"
                                            onClick={() => (
                                                
                                                disconnectWalletMobile()
                                                
                                                )}
                                                sx={{
                                                    width: "11.5rem", height: '3rem', backgroundColor: 'transparent', color: 'white', fontSize: '1rem', 
                                                    fontFamily: "ClashDisplay-Regular", fontWeight: 500, border: '1px solid white', borderRadius: '10px', textTransform: "none",
                                                    ":hover": { backgroundColor: "transparent", borderColor: '#18CDBC', color: '#18CDBC', }
                                                }}
                                            >
                                                Disconnect 
                                        </Button>
                                )}
                            </Box>
                            ): (
                            <Box>
                                 <Button
                                    variant="outlined"
                                    onClick={() => handleWalletDisplay()}
                                    sx={{
                                        width: "11.5rem", height: '3rem', backgroundColor: 'transparent', color: 'white', fontSize: '1rem', 
                                        fontFamily: "ClashDisplay-Regular", fontWeight: 500, border: '1px solid white', borderRadius: '10px', textTransform: "none",
                                        ":hover": { backgroundColor: "transparent", borderColor: '#18CDBC', color: '#18CDBC', }
                                    }}
                                >
                                    Connect wallet
                                </Button>
                            </Box>
                            )}



                            { isAccountConnected() ? (
                                <Box>

                                    {isMinning ? (
                                        
                                        <CircularProgress />
                                    ): (
                                        <Box>
                                            {!isMobile() ? (
                                                    
                                                    
                                                <Box>
                                                    {!isValidChainForMinting() ? (

                                                        <Button 
                                                            variant="outlined"
                                                            onClick={() => {
                                                                setDialogMessage('Make sure you are connected to Ethereum Mainnet before minting!')
                                                                setDialogIndicator('info')
                                                                setDialogStatus(true)
                                                            }}

                                                            sx={{
                                                                width: "11.5rem", height: '3rem', backgroundColor: 'transparent', color: 'white', fontSize: '1rem', 
                                                                fontFamily: "ClashDisplay-Regular", fontWeight: 500, border: '1px solid white', borderRadius: '10px', textTransform: "none",
                                                                ":hover": { backgroundColor: "transparent", borderColor: '#18CDBC', color: '#18CDBC', }
                                                            }}
                                                            >
                                                                Check Chain 
                                                        </Button>
                                                    ):(

                                                    <Box>
                                                        {!isSigned ? (
                                                            <Button 
                                                                variant="outlined"
                                                                onClick={() => (
                                                                    setSignedDialog(true)
                                                                )} 
                                                                sx={{
                                                                    width: "11.5rem", height: '3rem', backgroundColor: '#ffffff', color: '#000000', fontSize: '1rem', 
                                                                    fontFamily: "ClashDisplay-Regular", fontWeight: 500, border: '1px solid white', borderRadius: '10px', textTransform: "none",
                                                                    ":hover": { backgroundColor: '#18CDBC', color: '#ffffff', opacity: '1', fontStyle: 'bold'}
                                                                }}
                                                                
                                                                >
                                                                    Sign Agreement
                                                            </Button>
                                                        ): (
                                                            <Button 
                                                                variant="outlined"
                                                                onClick={() => (
                                                                    desktopMint() 
                                                                )} 
                            
                                                                sx={{
                                                                    width: "11.5rem", height: '3rem', backgroundColor: '#ffffff', color: '#000000', fontSize: '1rem', 
                                                                    fontFamily: "ClashDisplay-Regular", fontWeight: 500, border: '1px solid white', borderRadius: '10px', textTransform: "none",
                                                                    ":hover": { backgroundColor: '#18CDBC', color: '#ffffff', opacity: '1', fontStyle: 'bold'}
                                                                }}
                                                                
                                                                >
                                                                    Mint  
                                                            </Button>
                                                        )}
                                                    </Box>

                                                    )}
                                                </Box>

                                            ): (
                                                <Box>
                                                
                                                    {!isValidMobileChain ? (
                                                        <Button 
                                                            variant="outlined"
                                                        

                                                            sx={{
                                                                width: "11.5rem", height: '3rem', backgroundColor: '#ffffff', color: '#000000', fontSize: '1rem', 
                                                                fontFamily: "ClashDisplay-Regular", fontWeight: 500, border: '1px solid white', borderRadius: '10px', textTransform: "none",
                                                                ":hover": { backgroundColor: '#18CDBC', color: '#ffffff', opacity: '1', fontStyle: 'bold'}
                                                            }}
                                                            
                                                            >
                                                                    Check chain
                                                        </Button>

                                                    ):(
                                                        <Box>
                                                            <Button 
                                                                variant="outlined"
                                                                onClick={() => (
                                                                
                                                                setSignedMobileDialog(true)
                                                                
                                                                )} 

                                                                sx={{
                                                                    width: "11.5rem", height: '3rem', backgroundColor: '#ffffff', color: '#000000', fontSize: '1rem', 
                                                                    fontFamily: "ClashDisplay-Regular", fontWeight: 500, border: '1px solid white', borderRadius: '10px', textTransform: "none",
                                                                    ":hover": { backgroundColor: '#18CDBC', color: '#ffffff', opacity: '1', fontStyle: 'bold'}
                                                                }}
                                                                
                                                                >
                                                                        Mint 
                                                            </Button>
                                                    
                                                            <Mint signedMobileDialog={signedMobileDialog} handleSignedMobileDialog={handleSignedMobileDialog} mobileMint={mobileMint}/> 
                                                        </Box> 
                                                        )
                                                    }
                                                </Box>

                                                
                                            )}
                                        </Box>
                                    )}


                                </Box>
                            ): (


                                <Chip
                                    variant="outlined"
                                    
                                    sx={{
                                        width: "11.5rem", height: '3rem', backgroundColor: 'transparent', color: 'white', fontSize: '1rem', 
                                        fontFamily: "ClashDisplay-Regular", fontWeight: 500, border: '1px solid gray', borderRadius: '10px', textTransform: "none",
                                    }}
                                    label="Connect to mint "
                                    >
                                </Chip>

                            )}

               

                    </Stack>
                

                  {/* Alert Message */}


                  {isAccountConnected() &&
                      
                    <Stack 
                        direction={{xs: 'column', sm: 'column', md: 'column', lg: 'row'}}
                        justifyContent="center" 
                        alignItems="center"
                        maxWidth="100%"
                        >

                        <Chip

                            sx={{ color: messageIndicator , width: '100%'}}
                            label={messageInfo} 
                            variant="outlined"
                            fontFamily="ClashDisplay-Light"
                            >
                        </Chip> 

                        {detectError() && 
                          <Button 
                             variant="text"
                             sx={{ color: '#18CDBC'}}
                             onClick={() => {
                                setErrorDisplay(true)  
                             }}
                             >
                                view 
                             </Button>
                        }
                    </Stack>

                   }
   




                </Stack>

                {/** Wallet Options */}
                    <Dialog
                        sx={{ color: '#111321', borderRadius: '0', maxWidth: "100%" }}
                        open={openWallet}
                        TransitionComponent={Transition}
                        keepMounted
                        onClose={handleWalletClosed}
                        aria-describedby="Wallet Options"
                        PaperProps={{ sx: { borderRadius: "12px", background: "none", margin: "16px" } }}
                    >
                        <AppBar sx={{ position: 'relative', backgroundColor: '#111321', boxShadow: 0, padding: "30px 20px" }}>
                            <Toolbar sx={{ padding: 0, minHeight: "unset", display: "flex", alignItems: "flex-start", justifyContent: "space-between" }}>
                                <Typography sx={{ maxWidth: "85%", fontSize: "24px", lineHeight: 1.2 }} variant="h6" component="div" fontFamily="ClashDisplay-Medium">
                                    Choose one of the supported wallets
                                </Typography>
                                <IconButton
                                    edge="start"
                                    color="inherit"
                                    onClick={handleWalletClosed}
                                    aria-label="close"
                                    sx={{ mr: "-8px", mt: "-8px" }}
                                >
                                    <CloseIcon />
                                </IconButton>
                            </Toolbar>
                        </AppBar>
                        <DialogContent
                            sx={{ background: "#111321", padding: "2px 20px 30px", gap: "8px" }}
                        >
                            <Stack
                                direction={{ sm: "column", md: "row" }}
                                justifyContent="center"
                                alignItems="center"
                            >
                                {!isMobile() ? (
                                   <Stack
                                        direction="row"
                                        spacing={5}
                                        justifyContent="center"
                                        alignItems="center"
                                    >
                                        <IconButton
                                            size="large"
                                            aria-label="remove"
                                            variant="outlined"
                                            onClick={() => connectWalletHandler()}
                                            sx={{ color: 'white'}}
                                        >
                                            <WalletIcon icon={metamaskIcon}/>
                                        </IconButton>
                                        <IconButton
                                            size="large"
                                            aria-label="remove"
                                            variant="outlined"
                                            onClick={() => connectCoinBase()}
                                            sx={{ color: '#ffffff', backgroundColor: 'lightblue', height: '30px', borderRadius: '5%', 
                                            ":hover": { backgroundColor: '#18CDBC', color: '#ffffff', opacity: '1', fontStyle: 'bold'}}}
                                        >
                                            <WalletIcon icon={coinbaseIcon}/>
                                        </IconButton>
                                    </Stack>
                                ) : (
                                   <Stack
                                        sx={{ flexDirection: "column", justifyContent: "space-between", gap: "8px", width: "100%" }}
                                    >
                                        <Button
                                            aria-label="remove"
                                            variant="outlined"
                                            onClick={() => {
                                                connectMobileWallet();
                                                setOpenWallet(false);
                                            }}
                                            sx={{ justifyContent: "space-between", width: "100%", border: "1px solid rgba(171, 179, 243, 0.5)", height: "54px", borderRadius: "10px", p: "10px" }}
                                        >
                                            <Stack direction="column" gap="4px" alignItems="flex-start">
                                                <Typography sx={{ fontFamily: "ClashGrotesk-Regular", fontSize: "16px", lineHeight: "20px", color: "#FFFFFF", textTransform: "none" }}>Pay via WalletConnect</Typography>
                                                <Typography sx={{ fontFamily: "ClashGrotesk-Regular", fontSize: "9px", lineHeight: "11px", color: "#70E8FC", textTransform: "uppercase", letterSpacing: "0.07em" }}>Includes Metamask</Typography>
                                            </Stack>
                                            <CardMedia
                                                sx={{ width: { xs: "28px", sm: "28px", md: "28px" }, objectFit: "contain" }}
                                                component="img"
                                                src={walletconnectIcon}
                                                alt="Coinbase"
                                            />
                                        </Button>
                                        <Button
                                            aria-label="remove"
                                            variant="outlined"
                                            onClick={() => connectCoinBase()}
                                            sx={{ justifyContent: "space-between", width: "100%", border: "1px solid rgba(171, 179, 243, 0.5)", height: "54px", borderRadius: "10px", p: "10px" }}
                                        >
                                            <Typography sx={{ fontFamily: "ClashGrotesk-Regular", fontSize: "16px", lineHeight: "20px", color: "#FFFFFF", textTransform: "none" }}>Pay via coinbase</Typography>
                                            <CardMedia
                                                sx={{ width: {xs: "100px", sm: "100px", md: "100px"}, objectFit: "contain" }}
                                                component="img"
                                                src={coinbaseIcon2}
                                                alt="Coinbase"
                                            />
                                        </Button>
                                    </Stack>
                                )}
                            </Stack>
                        </DialogContent>
                    </Dialog>

                     {/* Signing option  */}
                     <Dialog
                        
                        sx={{ color: '#111321', borderRadius: '0'}}
                        open={signedDialog}
                        TransitionComponent={Transition}
                        keepMounted
                        onClose={handleSignedDialog}
                        aria-describedby="Wallet Options"
                        maxWidth={"sm"}
                    
                    >

                        <AppBar sx={{ position: 'relative', backgroundColor: '#111321', boxShadow: 0}}>
                            <Toolbar>
                                <IconButton
                                edge="start"
                                color="inherit"
                                onClick={handleSignedDialog}
                                aria-label="close"
                                >
                                <CloseIcon />
                                </IconButton>
                               
                                <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div" fontFamily="ClashDisplay-Light">
                                  Terms of Agreement
                                </Typography>
                            </Toolbar>
                        </AppBar>
                        <DialogContent                         
                            sx={{ background: '#111321'}}>

                            <Stack 

                                direction="column"
                                spacing={1}
                                justifyContent="center"
                                alignItems="center">

                                    <Typography 
                                        variant="h5"
                                        wrap="wrap"
                                        color="white"
                                        fontFamily="ClashDisplay-Light"
                                        component="div">
                                            {SignMessage}
                                    </Typography>

                                    <Button 
                                        variant="text"
                                        onClick={() => (
                                            window.open('https://www.zoopcards.com/en/terms-of-service')
                                        )} 
    
                                        sx={{ 
                                            width: {xs: "10rem", sm: "10rem", md: "15rem", lg: "15rem"},
                                            height: '2.5rem', backgroundColor: 'transparent',  color: 'white', fontSize: '.7rem', 
                                            fontStyle: 'bold',  fontFamily: "ClashDisplay-Bold",border: '2px solid black',     opacity: '.8',
                                            ":hover": { backgroundColor: '#18CDBC', color: '#ffffff', opacity: '1', fontStyle: 'bold'}
                                        }}
                                        >
                                            Terms of Agreement 
                                    </Button>              
                                    
                                    {!isMobile()  ? (
                                            <Box>
                                                {isCoinBase && 
                                                    <Button 
                                                        variant="outlined"
                                                        onClick={() => (
                                                            signAgreementDesktopCB(), 
                                                            setSignedDialog(false)
                                                        )} 
                    
                                                        sx={{ 
                                                            width: {xs: "10rem", sm: "10rem", md: "15rem", lg: "15rem"},
                                                            height: '2.5rem', backgroundColor: 'transparent',  color: 'white', fontSize: '1rem', 
                                                            fontStyle: 'bold',  fontFamily: "ClashDisplay-Bold",border: '2px solid black',   borderColor: 'white', borderRadius: '10px',  opacity: '.8',
                                                            ":hover": { backgroundColor: '#18CDBC', color: '#ffffff', opacity: '1', fontStyle: 'bold'}
                                                        }}
                                                        >
                                                            Agree 
                                                    </Button>  
                                                }

                                                {isMetaMask && 
                                                    <Button 
                                                        variant="outlined"
                                                        onClick={() => (
                                                            signAgreementDesktop(), 
                                                            setSignedDialog(false)
                                                        )} 
                    
                                                        sx={{ 
                                                            width: {xs: "10rem", sm: "10rem", md: "15rem", lg: "15rem"},
                                                            height: '2.5rem', backgroundColor: 'transparent',  color: 'white', fontSize: '1rem', 
                                                            fontStyle: 'bold',  fontFamily: "ClashDisplay-Bold",border: '2px solid black',   borderColor: 'white', borderRadius: '10px',  opacity: '.8',
                                                            ":hover": { backgroundColor: '#18CDBC', color: '#ffffff', opacity: '1', fontStyle: 'bold'}
                                                        }}
                                                        >
                                                            Agree
                                                    </Button>  
                                                }
                                            </Box>
                                    ):(                                     
                                            <Button  
                                                variant="outlined"
                                                onClick={() => ( mobileMint())}
            
                                                sx={{ 
                                                    width: {xs: "10rem", sm: "10rem", md: "15rem", lg: "15rem"},
                                                    height: '2.5rem', backgroundColor: 'transparent',  color: 'white', fontSize: '.7rem', 
                                                    fontStyle: 'bold',  fontFamily: "ClashDisplay-Bold",border: '2px solid black',   borderColor: 'white', borderRadius: '10px',  opacity: '.8',
                                                    ":hover": { backgroundColor: '#18CDBC', color: '#ffffff', opacity: '1', fontStyle: 'bold'}
                                                }}
                                                >
                                                    mint 
                                            </Button>  
                                    )}                           

                            </Stack>
                        
                        </DialogContent>
                        
                    </Dialog>


                {/**
                    <ZoopMessage 
                        messageStatus={messageStatus}  
                        messageInfo={messageInfo}
                        messageIndicator={messageIndicator}
                        messageClose={messageClose} />
                */}



        </Stack>
        

        ): (
            <Minted walletAddress={currentWallet}/>
        )}
        </Box>
    ) : (
        SaleOver()
    )}

            {isAccountConnected() &&
                <Box>
                    <PassCollection   openNft={openNft} handleNftDialog={handleNftDialog}/>
                    <DialogMessage status={dialogStatus} handleDisplay={handleDisplay} message={dialogMessage} indicator={dialogIndicator}/>
                    <IErrorDisplay status={errordisplay}  handler={handleErrorDisplay} message={fullMessage}/>
                </Box>
            }

            <InstallSupportedWallet status={supportWalletInstalled} handleDisplay={handleSupportWalletInstalled}/>

    </Box>
    )

}

export default StartMint 