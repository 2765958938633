import React, {useState, useEffect} from 'react'

import CloseIcon from '@mui/icons-material/Close'
import Typography from '@mui/material/Typography' 
import Stack from '@mui/material/Stack'
import { IconButton, Box, Button, AppBar, Toolbar, Slide, Dialog, DialogContent, Grid, CardMedia, Card, CardContent, Alert } from '@mui/material'
import ZoopTypography from './ZoopTypography'



const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />
})

const DialogMessage = ({status, handleDisplay, message, indicator}) => {


    return (
        <Box>

               {/* Wallet Nft Gallery */}
               <Dialog
                        maxWidth="100%"
                        sx={{ color: '#111321', borderRadius: '0'}}
                        open={status}
                        TransitionComponent={Transition}
                        keepMounted
                        onClose={handleDisplay} 
                        aria-describedby="Display Message"
                    >

                        <AppBar sx={{ position: 'relative', backgroundColor: '#111321', boxShadow: 0, fontFamily: 'ClashDisplay-Regular'}}>
                            <Toolbar>
                                <IconButton
                                    edge="start"
                                    color="inherit"
                                    onClick={handleDisplay}
                                    aria-label="close"
                                    >
                                    <CloseIcon />
                                </IconButton>
                               
                                <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div" fontFamily="ClashDisplay-Bold">
                                    Attention !
                                </Typography>
                            </Toolbar>
                        </AppBar>

                        <DialogContent                       
                            sx={{ background: '#111321'}}>


                                <Stack 
                                    direction="column"
                                    spacing={5}
                                    justifyContent="center"
                                    alignItems="center">




                                    <Alert 
                                        variant="contained"
                                        severity={indicator}>
                                            <ZoopTypography variant="h5" text={message}  font="ClashDisplay-Medium"/>

                                    </Alert>


                                   

                                </Stack>

                               
                            
                        
                        </DialogContent>
                        
                    </Dialog>
        </Box>
    )

}

export default DialogMessage 