import CardMedia from '@mui/material/CardMedia'
import { Box } from '@mui/system'
import ReactPlayer from 'react-player'


// detect mobile browser
const isMobile = () => {
    const toMatch = [
        /Android/i,
        /webOS/i,
        /iPhone/i,
        /iPad/i,
        /iPod/i,
        /BlackBerry/i,
        /Windows Phone/i
    ]

    return toMatch.some((toMatchItem) => {
        return navigator.userAgent.match(toMatchItem)
    })
}

const defaultMediaURI = "https://zooppass.s3.amazonaws.com/passFrame.mp4"

const ZoopIframe = () => {

    if(!isMobile()) {
        return (
            <CardMedia
                component="video"
                muted 
                loop 
                autoPlay
                src={defaultMediaURI}
                alt='Pass Frame'
            />
        )
    }

    return (
        <CardMedia
            component="video"
            muted 
            loop 
            autoPlay
            playsInline 
            src={defaultMediaURI}
            alt='Pass Frame'
        />
    )
};

export default ZoopIframe 