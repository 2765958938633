import React from 'react';
import ReactDOM from 'react-dom/client';
import ZoopApp from './ZoopApp';

import './theme/main.css' 
import './theme/web/css/clash-display.css'
import './theme/web/css/clash-grotesk.css'

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
        <ZoopApp />
);

