import Stack from '@mui/material/Stack'


import ZoopIframe from '../Components/ZoopIframe'


const PassView = () => {
    return (
        <Stack 
            maxWidth={{xs: '100%', sm: '100%', md: '40%'}}
            direction="column"
            spacing={3}
            justifyContent="top"
            alignItems="top">

            <Stack
                maxWidth={{ xs: '100%', sm: '100%', md: '100%', lg: '100%'}}
                justifyContent="center"
                alignItems="center" 
                display="flex"
                sx={{marginTop: { xs: '10rem', sm: '10rem', md: '2rem', lg: '2rem'}, marginLeft: {sm: 'auto', md: '0', lg: '0'}, marginRight: {sm: 'auto', md: '0', lg: '0'}}}>
                <ZoopIframe/>

            </Stack>


           
        </Stack>


    )
}

export default PassView 