

import React  from 'react' 


import AppBar from '@mui/material/AppBar'
import Box from '@mui/material/Box'
import Toolbar from '@mui/material/Toolbar' 
import Stack from '@mui/material/Stack' 


import Button from '@mui/material/Button' 
import LinearProgress from '@mui/material/LinearProgress'


import ZoopIcon from './ZoopIcon' 
import { useMoralis } from 'react-moralis'
import { Chip, CssBaseline, Divider } from '@mui/material'


// detect mobile browser
const isMobile = () => {
    const toMatch = [
        /Android/i,
        /webOS/i,
        /iPhone/i,
        /iPad/i,
        /iPod/i,
        /BlackBerry/i,
        /Windows Phone/i
    ]

    return toMatch.some((toMatchItem) => {
        return navigator.userAgent.match(toMatchItem)
    })
}

const Header = () => {

    return (
        <Box 
            mt={{md: 5, lg: 5}}
            display="flex"
            justifyContent="center"
            alignItems="center"
            width="100%">
            <AppBar position="static" sx={{ flewGrow: 1, backgroundColor: 'transparent', boxShadow: 0}}>
                <Toolbar sx={{ paddingLeft: "0 !important", paddingRight: "0 !important" }}>
                    <Stack 
                        direction="column"
                        maxWidth="100%" 
                        width="100%"
                        spacing={2}>

                            
                            {!isMobile() ? (
                                <ZoopIcon width="100px" height="100%" />

                            ): (
                                <ZoopIcon width="100px" height="100%" />
                            )}

                            <Divider sx={{backgroundColor: 'white'}}/> 
                    </Stack>




                    

                   
                </Toolbar>
            </AppBar>
        </Box>
    )
}

export default Header 