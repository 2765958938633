import React, { useEffect, useState } from 'react' 


import Stack from '@mui/material/Stack'

import ZoopIconLink from './ZoopIconLinks'
import ZoopTypography from './ZoopTypography'

import Typography from '@mui/material/Typography' 

import facebookIcon from '../icons/facebook.png'
import instaIcon from '../icons/insta.png'
import telegramIcon from '../icons/telegram.png'
import twitterIcon from '../icons/tweet.png'
import { IconButton, Button } from '@mui/material'


import PassCollection  from './PassGallery'  


// react-share
import {
    FacebookShareButton, 
    TwitterShareButton, 
    TelegramShareButton,
} from 'react-share'

import {

    FacebookIcon, 
    TwitterIcon, 
    TelegramIcon, 
} from 'react-share'







const Minted = (props) => {

    const {walletAddress} = props
    const [openNft, setOpenNft] = useState(false) 
    const handleNftDialog = () => {
        setOpenNft(!openNft) 
    }

   

    return (
        <Stack 
            maxWidth="100%"
            sx={{ width: '400px'}}
            direction="column"
            spacing={5}
            alignItems={{sm: 'center', md: 'left'}}        
            justifyContent={{sm: 'center', md: 'left'}}>

                <Stack 
                     justifyContent="center"
                     alignItems="center"
                    direction="column"
                    spacing={10}>
                        <Typography 
                            variant="h4"
                            component="div"
                            wrap="wrap"
                            fontFamily="monospace"
                            color="white">
                                The wait is over!
                        </Typography>
                </Stack>

                <Stack 
                      justifyContent="center"
                      alignItems="center"
                    direction="column"
                    spacing={2}>
                        <ZoopTypography variant="p" text="Your Priority Pass was successfully minted!"/>
                        <ZoopTypography variant="p" text="Share this moment with the world via your "/>
                        <ZoopTypography variant="p" text="favorite social media"/>
                </Stack>

                <Stack 
                    justifyContent="center"
                    alignItems="center"
                    direction="row"
                    spacing={5}>

                    
                        <FacebookShareButton
                            url={"https://mint.www.zoopcards.com/"}
                            quote="Mint Zoop Priority Pass Here"
                            >

                                <FacebookIcon size={32} round/>

                        </FacebookShareButton>



                        <TwitterShareButton
                            url={"https://mint.www.zoopcards.com/"}
                            quote="Mint Zoop Priority Pass Here"
                            >

                                <TwitterIcon size={32} round/>

                        </TwitterShareButton>


                        <TelegramShareButton 
                            url={"https://mint.www.zoopcards.com/"}
                            quote="Mint Zoop Priority Pass Here"
                            >

                                <TelegramIcon size={32} round/>

                        </TelegramShareButton>


                   
                    {/*
                        <IconButton
                            size="large"
                            aria-label="facebook-icon"
                            variant="outlined"
                            sx={{ backgroundColor: '#21264E'}}

                            >
                            <ZoopIconLink width="20px" height="20px" src={instaIcon}/>

                        </IconButton>
                    */}


                </Stack>

                <Stack 
                    width="100%"
                    direction="column"
                    spacing={3}
                    justifyContent="center"
                    alignItems="center">

                    <Stack 
                        width="80%"
                        direction="column"
                        spacing={3}
                        justifyContent="center"
                        alignItems="center">

                        <Button    
                            sx={{ 
                                width: {xs: "10rem", sm: "10rem", md: "15rem", lg: "15rem"},
                                height: '2.5rem', width: '100%', backgroundColor: 'transparent',  color: 'white', fontSize: '1rem', 
                                fontStyle: 'bold',  fontFamily: "ClashDisplay-Regular",border: '2px solid black',   borderColor: 'white', borderRadius: '10px',  opacity: '.8',
                                ":hover": { backgroundColor: '#18CDBC', color: '#ffffff', opacity: '1', fontStyle: 'bold'}
                            }}
                            variant="outlined"
                            onClick={() => ( handleNftDialog())}>
                                View Minted Pass 
                        </Button>

                    </Stack>
                </Stack>


                <PassCollection  walletAddress={walletAddress} openNft={openNft} handleNftDialog={handleNftDialog}/>




             

               
        </Stack>
    )
}

export default Minted 