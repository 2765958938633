

import React from 'react' 


import Box from '@mui/material/Box'
import Stack from '@mui/material/Stack'


import ZoopIcon from './ZoopIcon'
import ZoopIconLink from './ZoopIconLinks'
import ZoopTypography from './ZoopTypography'

import facebookIcon from '../icons/facebook.png'
import discordIcon from '../icons/discord.png'
import instaIcon from '../icons/insta.png'
import telegramIcon from '../icons/telegram.png'
import youtubeIcon from '../icons/youtube.png'
import twitterIcon from '../icons/tweet.png'

import { Divider, IconButton, Link } from '@mui/material'



const Footer = () => {

    return (
        <Box
            mt={12}
            bottom={0}
            left={0}
            maxWidth="100%"
            justifyContent="center" 
            alignItems="center"
            display="flex">
            <Stack 
                maxWidth="100%"

                direction="column"
                spacing={5}>
                            <Divider sx={{backgroundColor: 'white'}}/> 

                <Stack 
                    maxWidth="100%"
                    justifyContent="center" 
                    alignItems="center"
                    direction={{xs: 'column', sm: 'column', md: 'row', lg: 'row'}}
                    spacing={{xs: 3, sm: 3, md: '5%', lg: '20%', xl: 20}}>


                        <Stack 
                            maxWidth="100%"
                            direction="column"
                            spacing={1}>

                                <ZoopIcon width="100px" height="50%" />

                        </Stack>



                        <Stack 
                            justifyContent="center" 
                            alignItems="center"
                            maxWidth="100%"
                            direction="column"
                        
                            spacing={{xs: 1, sm: 1, md: 1, lg: 5}}>

                                <Link href="https://www.zoopcards.com/about">
                                    <ZoopTypography variant="h7" text="About" font="ClashDisplay-Medium"/>
                                </Link>
                                
                                <Link href="https://www.zoopcards.com/blog">
                                    <ZoopTypography variant="h7" text="Blog" font="ClashDisplay-Medium"/>
                                </Link>

                                <Link href="https://zoopcards.gitbook.io/zoop/">
                                    <ZoopTypography variant="h7" text="Whitepaper" font="ClashDisplay-Medium"/>
                                </Link>


                        </Stack>

                        <Stack 
                            maxWidth="100%"
                            justifyContent="center" 
                            alignItems="center"
                            direction="column"
                            spacing={{xs: 1, sm: 1, md: 1, lg: 5}}>

                                <Link href="https://www.zoopcards.com/terms-of-policy">
                                    <ZoopTypography variant="h7" text="Privacy Policy" font="ClashDisplay-Medium"/>
                                </Link>
                                <Link href="https://www.zoopcards.com/terms-of-service">
                                    <ZoopTypography variant="h7" text="Terms and conditions" font="ClashDisplay-Medium"/>
                                </Link>


                        </Stack>

                        <Stack 
                                    maxWidth="100%"

                             justifyContent="center" 
                             alignItems="center"
                            direction="column"
                            spacing={{xs: 1, sm: 1, md: 1, lg: 5}}>
                                <ZoopTypography variant="h7" text="Follow us on social media" font="ClashDisplay-Medium"/>
                                
                                <Stack 
                                    maxWidth="100%"
                                    direction='row'
                                    spacing={3}
                                    justifyContent="center"
                                    alignItems="center">

                                            <IconButton
                                                size="large"
                                                aria-label="facebook-icon"
                                                variant="outlined"
                                                >
                                                <ZoopIconLink width="20px" height="20px" src={facebookIcon} to={"https://www.facebook.com/zoop.cards"} />

                                            </IconButton>

                                            <IconButton
                                                size="large"
                                                aria-label="facebook-icon"
                                                variant="outlined"
                                                >
                                                    <ZoopIconLink width="20px" height="20px" src={twitterIcon} to={"https://twitter.com/zoopcards"}/>

                                            </IconButton>

                                            <IconButton
                                                size="large"
                                                aria-label="facebook-icon"
                                                variant="outlined"
                                                >
                                                     <ZoopIconLink width="20px" height="20px" src={discordIcon} to={"https://discord.com/invite/NQAqqJkws4"}/>

                                            </IconButton>


                                            <IconButton
                                                size="large"
                                                aria-label="facebook-icon"
                                                variant="outlined"
                                                >
                                                     <ZoopIconLink width="20px" height="20px" src={youtubeIcon} to={"https://www.youtube.com/channel/UCP3WHcM3SB2zRcyti1misQw"}/>

                                            </IconButton>



                                            <IconButton
                                                size="large"
                                                aria-label="facebook-icon"
                                                variant="outlined"
                                                >
                                                     <ZoopIconLink width="20px" height="20px" src={telegramIcon} to={"https://t.me/+Ku7EqeY1z_s4ZDBh"}/>

                                            </IconButton>


                                            <IconButton
                                                size="large"
                                                aria-label="facebook-icon"
                                                variant="outlined"
                                                >
                                                    <ZoopIconLink width="20px" height="20px" src={instaIcon} to={"https://www.instagram.com/thisiszoop/"}/>

                                            </IconButton>







                                    </Stack>

                        </Stack>


                </Stack>

                <Divider sx={{backgroundColor: 'white'}}/> 
                <Stack 
                            maxWidth="100%"

                    direction="column"
                    justifyContent="center"
                    alignItems="center">
                            <ZoopTypography variant="h7" text="Zoop 2022. All right reserved" font="ClashDisplay-Light"/>

                    </Stack>

            </Stack>



        </Box>
    )
}

export default Footer 