import Icon from '@mui/material/Icon'
import logo from '../icons/zoop_logo.png'

import React, {useState} from 'react'
const ZoopIcon = ({width, height}) => {


    return (
            
            <img width={width}  height={height}  src={logo}/>
    )
}

export default ZoopIcon 