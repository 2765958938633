

import React from 'react' 


import MintService from './Service/Session' 
import { MoralisProvider } from 'react-moralis'

const MORALIS_APP_ID = process.env.REACT_APP_MORALIS_APP_ID
const MORALIS_SERVER_URL = process.env.REACT_APP_MORALIS_SERVER_URL

const ZoopApp = () => {

    return (
        <MoralisProvider appId={MORALIS_APP_ID} serverUrl={MORALIS_SERVER_URL}>
        <MintService/>
        </MoralisProvider>
    )
}

export default ZoopApp 