import CardMedia from '@mui/material/CardMedia'
//import passFrame from '../icons/passFrame.mp4'

const defaultPassURI = "https://zooppass.s3.amazonaws.com/Zoop+Priority+Pass+Batch%231-squareCC.mp4"
const ZoopPassFrame = ({alt_name}) => {
    return (
        <CardMedia
            component="video"
            autoPlay 
            loop 
            image={defaultPassURI}
            alt={alt_name}/>
    )
}

export default ZoopPassFrame 