import { Link } from "@mui/material"
const ZoopIconLink  = ({width, height, src, to}) => {


    return (
            
        <Link href={to}>
            <img width={width}  height={height}  src={src}/>
        </Link>
    )
}

export default ZoopIconLink