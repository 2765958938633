
import React, { useEffect, useState } from 'react' 



import { useMoralisWeb3Api, useMoralis } from 'react-moralis'

import axios from 'axios'
import CloseIcon from '@mui/icons-material/Close'
import Typography from '@mui/material/Typography' 
import Stack from '@mui/material/Stack'
import { IconButton, Box, Button, AppBar, Toolbar, Slide, Dialog, DialogContent, Grid, CardMedia, Card, CardContent, Alert } from '@mui/material'
import ZoopPassFrame from './IPass'
import CircularProgress from '@mui/material/CircularProgress';
import ZoopTypography from './ZoopTypography'


const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />
})


const MORALIS_APP_ID = process.env.REACT_APP_MORALIS_APP_ID
const MORALIS_SERVER_URL = process.env.REACT_APP_MORALIS_SERVER_URL
const NET_NAME = process.env.REACT_APP_NET_NAME

const defaultContractAddress = process.env.REACT_APP_CONTRACT_ADDRESS 
const defaultPassURI = process.env.REACT_APP_PASS_URI
const PassSize = localStorage.getItem('pass_size')

const etherscan_url = "https://etherscan.io/token/"
const opensea_url = "https://opensea.io/assets/"


const PassCollection = ({ openNft, handleNftDialog }) => {




    const [passCollection, updatePassCollection] = useState([])
    const [loading, setLoading] = useState(false) 
    
    
    const Web3Api = useMoralisWeb3Api() 
    const { Moralis } = useMoralis() 

    // open wallet nfts dialog 
    /*
    const extractNftImage = async (tokenUri) => {

        const params = {theUrl: tokenUri}
        try{
            const metadata = await Moralis.Cloud.run("fetchJSON", params)
            return metadata.data.image 
        }catch(error){
            console.error(error) 
        }
    }
    */


    const  openEtherscan = (token_id) => {
        return window.open(`${etherscan_url}${defaultContractAddress}?a=${token_id}`)
    }

    const openOpenSea = (token_id) => {
        return window.open(`${opensea_url}${defaultContractAddress}/${token_id}`)
    }


    useEffect(() => {

        setLoading(true) 
        const interval = setInterval(() => {
            const loadMintedPass = async () => {

                const moralis_options = {appId: MORALIS_APP_ID, serverUrl: MORALIS_SERVER_URL}
                Moralis.start(moralis_options)
                const walletAddress = localStorage.getItem('currentwallet')

        
        
                const options = { 
                chain: NET_NAME,
                address: walletAddress, 
                token_address: defaultContractAddress 
                }
        
        
                const walletNfts = await Web3Api.account.getNFTsForContract(options)
                            .then((res) => {
                                updatePassCollection(res.result)
                                localStorage.setItem('pass_size', res.result.length)       
                                setLoading(false)                       

                            }).catch((error) => {
                                console.log(error) 
                            })

            }

            loadMintedPass() 
        }, 1000)
        return () => clearInterval(interval) 

    }, [])
   

    /*

    console.log('Minted Pass: ', passCollection)
    console.log('Is Loading ? ', loading) 

    console.log('Storage Size: ', PassSize)
    console.log('PassCollection Size: ', passCollection.length) 

    */

    console.log('NET NAME: ', NET_NAME)
      


    return (
        <Box>
               {/* Wallet Nft Gallery */}
               <Dialog
                        maxWidth="100%"
                        sx={{ color: '#111321', borderRadius: '0'}}
                        open={openNft}
                        TransitionComponent={Transition}
                        keepMounted
                        onClose={handleNftDialog}
                        aria-describedby="Wallet Options"
                        fullScreen 
                    >

                        <AppBar sx={{ position: 'relative', backgroundColor: '#111321', boxShadow: 0, fontFamily: 'ClashDisplay-Regular'}}>
                            <Toolbar>
                                <IconButton
                                    edge="start"
                                    color="inherit"
                                    onClick={handleNftDialog}
                                    aria-label="close"
                                    >
                                    <CloseIcon />
                                </IconButton>
                               
                                <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div" fontFamily="ClashDisplay-Bold">
                                  My Priority Passes 
                                </Typography>
                            </Toolbar>
                        </AppBar>

                        <DialogContent                       
                            sx={{ background: '#111321'}}>


                                <Stack 
                                    direction="column"
                                    spacing={5}
                                    justifyContent="center"
                                    alignItems="center">

                                    <Alert severity="info" variant="contained">
                                        <ZoopTypography variant="h5" text="It can take a few minutes for the pass to appear after it's minted" font="ClashDisplay-Regular"/>
                                    </Alert>

                                    {!loading ? (


                                <Grid
                                    container 
                                    rowSpacing={{xs: 2, sm: 2, md: 5, lg: 5}}
                                    columnSpacing={{xs: 2, sm: 2, md: 2, lg: 5, xl: 5}}
                                    alignItems="center"
                                    justifyContent="center" 
                                    maxWidth="100%">

                                    
                                    {passCollection.map(( pass, key) => (
                                        <Grid item 
                                            key={key} 
                                            width={{xs: '80%', md: '40%', lg: '30%', xl: '25%'}}
                                            alignItems="center"
                                            justifyContent="center" >

                                                <Card
                                                    sx={{ boxShadow: 2, border: 'none'}}
                                                    >

                                                     <ZoopPassFrame alt_name={pass.name}/>
                                                </Card>

                                                <CardContent>
                                                   <Stack 
                                                        direction="column"
                                                        spacing={3}
                                                        justifyContent="center"
                                                        alignItems="center">

                                                        <Typography
                                                            color="white"
                                                            variant="h6"
                                                            component="div"
                                                            fontFamily="ClashDisplay-Regular"
                                                            >
                                                                {`Token ID  #${pass.token_id}`}
                                                            </Typography>

                                                        <Stack      
                                                            direction="row"
                                                            spacing={3}>
                                                                <Button  
                                                                    sx={{ background: 'transparent', color: 'white', fontFamily: "ClashDisplay-Regular"}}  
                                                                    variant="text"
                                                                    onClick={() => (openEtherscan(pass.token_id))}>
                                                                    View on EtherScan 
                                                                </Button>
                                                                <Button 
                                                                    sx={{ background: 'transparent', color: 'white', fontFamily: "ClashDisplay-Regular"}} 
                                                                    variant="text"
                                                                    onClick={() => ( openOpenSea(pass.token_id))}>
                                                                    View on OpenSea
                                                                </Button>
                                                        </Stack>

                                                        </Stack>
                                                </CardContent>

                                        </Grid>
                                    ))}
                              
                                </Grid>
                                  ): (
                                    <Stack 
                                        direction="column"
                                        spacing={3}
                                        justifyContent="center"
                                        alignItems="center">
                                            <Typography sx={{ color: 'white'}} bottom={0} variant="h6" component="div" fontFamily="ClashDisplay-Light">
                                                 Loading Your Pass Please Wait......
                                            </Typography>

                                            <CircularProgress/>
                                    </Stack>

                                )}


                                   

                                </Stack>

                               
                            
                        
                        </DialogContent>
                        
                    </Dialog>
        </Box>
    )
}

export default PassCollection 