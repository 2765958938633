
import React from 'react'

import { Dialog, DialogContent, AppBar, Button, Box, Stack, Typography, Toolbar, Chip } from '@mui/material'
import { IconButton } from '@mui/material' 
import CloseIcon from '@mui/icons-material/Close'
import Slide from '@mui/material/Slide'



const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />
})


const IErrorDisplay = ({status, handler, message}) => {

    return (
        <Dialog
            sx={{ color: '#111321', borderRadius: '0'}}
            open={status}
            TransitionComponent={Transition}
            keepMounted
            onClose={handler}
            aria-describedby="Full Error Dialog"
            fullScreen 
        >

            <AppBar sx={{ position: 'relative', backgroundColor: '#111321', boxShadow: 0, fontFamily: 'ClashDisplay-Regular'}}>
                <Toolbar>
                    <IconButton
                        edge="start"
                        color="inherit"
                        onClick={handler}
                        aria-label="close"
                        >
                        <CloseIcon />
                    </IconButton>
                    
                    <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div" fontFamily="ClashDisplay-Bold">
                        Error Message 
                    </Typography>
                </Toolbar>
            </AppBar>

            <DialogContent             
                sx={{ backgroundColor: '#111321'}}>


                    <Stack
                        direction="column"
                        spacing={5}
                        justifyContent="center"
                        alignItems="center"
                        >

                        <Typography 
                            fontSize="40px"
                            lineHeight="20px"
                            textTransform="uppercase"
                            component="div"
                            wrap="wrap"
                            fontFamily="ClashDisplay-Regular"
                            color="#18CDBC">
                                Full Error 
                        </Typography>

                        <Box
                            justifyContent="center"
                            alignItems="center"
                            sx={{overflow: "hidden", textOverflow: "ellipsis", width: '50%'}}
                            >
                                 <Typography
                                    sx={{ color: '#ff3333', }}
                                    variant="h6"
                                    component="div"
                                    >
                                        {message.message}
                                </Typography>
                                            
                        </Box>


                    </Stack>



            </DialogContent>
        </Dialog>

    )
}

export default IErrorDisplay