


import React, { useState, useEffect } from 'react' 

import Box from '@mui/material/Stack'
import Stack from '@mui/material/Stack'
import ZoopTypography from '../Components/ZoopTypography'
import Typography from '@mui/material/Typography'
import CircularProgress from '@mui/material/CircularProgress'
import CloseIcon from '@mui/icons-material/Close'
import Dialog from '@mui/material/Dialog'
import DialogContent from '@mui/material/DialogContent'
import Slide from '@mui/material/Slide'
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline'
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline'
import { AppBar, Button, Checkbox, Chip,  Divider, IconButton, Link, Toolbar } from '@mui/material'
import {makeStyles} from '@mui/styles'  


const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />
})

const signMessage = process.env.REACT_APP_SIGN_MESSAGE


const Mint = (props) => {


    const {signedMobileDialog, handleSignedMobileDialog, mobileMint} = props 


    const [allow, setAllow] = useState(false)
    const allowMint = (event) => {
       setAllow(event.target.checked)
    }
  

    return (
        <Box>

                     {/* Signing option  */}
                     <Dialog
                        
                        sx={{ color: '#111321', borderRadius: '0'}}
                        open={signedMobileDialog}
                        TransitionComponent={Transition}
                        keepMounted
                        onClose={handleSignedMobileDialog}
                        aria-describedby="Wallet Options"
                        maxWidth={"sm"}
                    
                    >

                        <AppBar sx={{ position: 'relative', backgroundColor: '#111321', boxShadow: 0}}>
                            <Toolbar>
                                <IconButton
                                edge="start"
                                color="inherit"
                                onClick={handleSignedMobileDialog}
                                aria-label="close"
                                >
                                <CloseIcon />
                                </IconButton>
                               
                                <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div" fontFamily="ClashDisplay-Light">
                                  Terms of Agreement
                                </Typography>
                            </Toolbar>
                        </AppBar>
                        <DialogContent                         
                            sx={{ background: '#111321'}}>

                            <Stack 

                                direction="column"
                                spacing={1}
                                justifyContent="center"
                                alignItems="center">

                                    <Typography 
                                        variant="h5"
                                        wrap="wrap"
                                        color="white"
                                        fontFamily="ClashDisplay-Light"
                                        component="div">
                                            {signMessage}
                                    </Typography>

                                    <Stack 

                                        direction="row"
                                        spacing={1}
                                        justifyContent="center"
                                        alignItems="center">


                                            <Checkbox value={allow} onChange={allowMint} sx={{ color: 'white'}} />
                                                
                                                <Button 
                                                variant="text"
                                                onClick={() => (
                                                    window.open('https://www.zoopcards.com/en/terms-of-service')
                                                )} 
            
                                                sx={{ 
                                                    width: {xs: "10rem", sm: "10rem", md: "15rem", lg: "15rem"},
                                                    height: '2.5rem', backgroundColor: 'transparent',  color: 'white', fontSize: '.7rem', 
                                                    fontStyle: 'bold',  fontFamily: "ClashDisplay-Bold",     opacity: '.8',
                                                    ":hover": { backgroundColor: '#18CDBC', color: '#ffffff', opacity: '1', fontStyle: 'bold'}
                                                }}
                                                >
                                                    Terms of Agreement 
                                            </Button>  
                                        
                                    </Stack>            
                                    
                                    {!allow ? (
                    
                                    <Button  
                                        variant="outlined"
                                        disabled    
                                        sx={{ 
                                            width: {xs: "10rem", sm: "10rem", md: "15rem", lg: "15rem"},
                                            height: '2.5rem', backgroundColor: 'transparent',  color: 'white', fontSize: '.7rem', 
                                            fontStyle: 'bold',  fontFamily: "ClashDisplay-Bold",border: '2px solid black',   borderColor: 'white', borderRadius: '10px',  opacity: '.8',
                                            ":hover": { backgroundColor: '#18CDBC', color: '#ffffff', opacity: '1', fontStyle: 'bold'}
                                        }}
                                        >
                                            mint
                                    </Button>  
                                    ): (
                                            <Button  
                                                variant="outlined"
                                                onClick={() => ( mobileMint())}
            
                                                sx={{ 
                                                    width: {xs: "10rem", sm: "10rem", md: "15rem", lg: "15rem"},
                                                    height: '2.5rem', backgroundColor: 'transparent',  color: 'white', fontSize: '.7rem', 
                                                    fontStyle: 'bold',  fontFamily: "ClashDisplay-Bold",border: '2px solid black',   borderColor: 'white', borderRadius: '10px',  opacity: '.8',
                                                    ":hover": { backgroundColor: '#18CDBC', color: '#ffffff', opacity: '1', fontStyle: 'bold'}
                                                }}
                                                >
                                                    mint
                                            </Button>  
                                    )}
                                                    

                            </Stack>
                        
                        </DialogContent>
                        
                    </Dialog>
        </Box>
    )
}

export default Mint 